// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-react-deploying-a-gatsby-site-to-netlify-js": () => import("./../../../src/pages/blog/react/deploying-a-gatsby-site-to-netlify.js" /* webpackChunkName: "component---src-pages-blog-react-deploying-a-gatsby-site-to-netlify-js" */),
  "component---src-pages-blog-react-react-archive-js": () => import("./../../../src/pages/blog/react/react-archive.js" /* webpackChunkName: "component---src-pages-blog-react-react-archive-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-work-template-js": () => import("./../../../src/templates/work-template.js" /* webpackChunkName: "component---src-templates-work-template-js" */)
}

